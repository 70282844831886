import {
  collection,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from "firebase/firestore";
import { firestore } from "../utils/setupFirestore";

export class ResultModel {
  title: string;
  link: string;
  comments: string[];
  accepted: boolean;
  createdAt: Date;
  docId: string | undefined;

  constructor(
    title: string,
    link: string,
    comments: string[],
    accepted: boolean,
    createdAt: Date = new Date(),
    docId?: string,
  ) {
    this.title = title;
    this.link = link;
    this.comments = comments;
    this.accepted = accepted;
    this.createdAt = createdAt;
    this.docId = docId;
  }

  static _converter: FirestoreDataConverter<ResultModel> = {
    toFirestore: function (result: ResultModel) {
      return {
        title: result.title,
        link: result.link,
        comments: result.comments,
        accepted: result.accepted,
        created_at: result.createdAt,
      };
    },
    fromFirestore: function (
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions,
    ) {
      const data = snapshot.data(options);
      const createAtTimeStamp = data.created_at as Timestamp;
      return new ResultModel(
        data.title,
        data.link,
        data.comments,
        data.accepted,
        new Date(createAtTimeStamp.seconds * 1000),
        snapshot.id,
      );
    },
  };

  static collectionReference = collection(firestore, "results").withConverter(
    ResultModel._converter,
  );
}
