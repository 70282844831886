import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { ResultModel } from "./models/result.model";
import { fetchAllResults } from "./services/results.services";
import { useEffect, useState } from "react";
import { ResultsTable } from "./components/ResultsTable";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { AddResultButton } from "./components/AddResultButton";

function App() {
  const [results, setResults] = useState<ResultModel[]>([]);

  function fetchData() {
    fetchAllResults()
      .then(setResults)
      .catch((err) => console.error(err));
  }

  useEffect(fetchData, []);

  return (
    <Stack component={Container} minWidth={960}>
      <Typography
        variant="h4"
        marginTop={4}
        marginBottom={2}
        textAlign="center"
      >
        Open reviews in SE
      </Typography>

      <AddResultButton onSuccess={fetchData} />

      <Box marginTop={2} height={`calc(100vh - 127px - 68px)`}>
        <ResultsTable results={results} />
      </Box>

      <Typography variant="caption" textAlign="center" marginTop={2}>
        {`If you have any question, please contact `}
        <Link href="mailto:hey@cs.cmu.edu" underline="none" target="_blank">
          hey@cs.cmu.edu
        </Link>
      </Typography>
    </Stack>
  );
}

export default App;
