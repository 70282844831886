import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Dialog from "@mui/material/Dialog";
import { FormEvent, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import { ResultModel } from "../models/result.model";
import { addResult } from "../services/results.services";

export function AddResultButton({ onSuccess }: { onSuccess: () => void }) {
  const [open, setOpen] = useState<boolean>(false);
  const [commentAmount, setCommentAmount] = useState<number>(3);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    const comments = new Array(commentAmount)
      .fill("")
      .map((_, i) => formJson[`comment.${i}`]);

    const result = new ResultModel(
      formJson.title,
      formJson.link,
      comments,
      formJson.accepted === "accepted",
    );

    await addResult(result);

    handleClose();
    onSuccess();
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<AddCircleOutlineIcon />}
        sx={{ alignSelf: "flex-end" }}
        onClick={() => setOpen(true)}
      >
        Add a result
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          component: "form",
          onSubmit: onSubmit,
        }}
      >
        <DialogTitle textAlign="center">Add a review result</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You won't be able to edit your review result after submitting it, so
            make sure it's accurate.
          </DialogContentText>
          <TextField
            required
            margin="dense"
            name="title"
            label="Paper title"
            type="text"
            fullWidth
          />
          <TextField
            required
            margin="dense"
            name="link"
            label="Link of your paper"
            type="url"
            fullWidth
          />
          <TextField
            required
            select
            margin="dense"
            name="accepted"
            label="Accepted or rejected"
            fullWidth
            defaultValue={""}
          >
            <MenuItem value="accepted">Accepted</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
          </TextField>
          {new Array(commentAmount).fill("").map((_, index) => (
            <TextField
              key={index}
              required
              margin="dense"
              name={`comment.${index}`}
              label={`Received comment ${index + 1}`}
              type="text"
              fullWidth
            />
          ))}
          <Stack flexDirection="row" gap={2} marginTop={1}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<AddCircleOutlineIcon />}
              sx={{ textTransform: "none" }}
              onClick={() => setCommentAmount(commentAmount + 1)}
            >
              Add a comment
            </Button>
            <Button
              variant="outlined"
              fullWidth
              color="error"
              startIcon={<RemoveCircleOutlineIcon />}
              disabled={commentAmount <= 1}
              sx={{ textTransform: "none" }}
              onClick={() => setCommentAmount(commentAmount - 1)}
            >
              Remove a comment
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="large">
            Cancel
          </Button>
          <Button type="submit" size="large">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
