import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCM-td1uWnP4gUbLYD62TwXM4bn8386dCc",
  authDomain: "open-reviews-in-se.firebaseapp.com",
  projectId: "open-reviews-in-se",
  storageBucket: "open-reviews-in-se.appspot.com",
  messagingSenderId: "613625344301",
  appId: "1:613625344301:web:1f32e14fbda7f290bf63a4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const firestore = getFirestore(app);
