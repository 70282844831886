import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ResultModel } from "../models/result.model";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { CommentText } from "./CommentText";

const columns: GridColDef[] = [
  {
    field: "title",
    headerName: "Paper",
    width: 200,
    sortable: false,
    renderCell: ({ row }: { row: ResultModel }) => (
      <Link href={row.link} underline="none" target="_blank">
        {row.title}
      </Link>
    ),
  },
  {
    field: "comments",
    headerName: "Comments",
    flex: 1,
    sortable: false,
    renderCell: ({ row }: { row: ResultModel }) => (
      <Stack spacing={2} paddingY={1.5}>
        {row.comments.map((comment, index) => (
          <CommentText key={`${row.docId}-${index}`} comment={comment} />
        ))}
      </Stack>
    ),
  },
  {
    field: "accepted",
    headerName: "Accepted",
    width: 100,
    align: "center",
    renderCell: ({ row }: { row: ResultModel }) => (row.accepted ? "✅" : "❌"),
  },
  {
    field: "createdAt",
    headerName: "Collection date",
    width: 150,
    sortComparator: (a: ResultModel, b: ResultModel) =>
      dayjs(b.createdAt).diff(dayjs(a.createdAt), "second"),
    renderCell: ({ row }: { row: ResultModel }) =>
      dayjs(row.createdAt).format("DD/MM/YYYY"),
  },
];

export function ResultsTable({ results }: { results: ResultModel[] }) {
  return (
    <DataGrid<ResultModel>
      loading={results.length === 0}
      rows={results}
      getRowHeight={() => "auto"}
      getRowId={(row) => row.docId!}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      sx={{
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
      }}
      pageSizeOptions={[10, 25, 50]}
      disableRowSelectionOnClick
      disableVirtualization
      disableColumnMenu
      disableColumnFilter
      disableDensitySelector
    />
  );
}
