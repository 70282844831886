import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";

export function CommentText({ comment }: { comment: string }) {
  const [ellipsis, setEllipsis] = useState<boolean>(true);

  const style = ellipsis
    ? {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "3",
        WebkitBoxOrient: "vertical",
        cursor: "pointer",
      }
    : {
        cursor: "pointer",
      };

  return (
    <Stack flexDirection="row" width="100%">
      <Box marginRight={1}>●</Box>
      <Typography
        variant="body2"
        sx={style}
        onClick={() => setEllipsis(!ellipsis)}
      >
        {comment}
      </Typography>
    </Stack>
  );
}
