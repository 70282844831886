import { doc, setDoc, query, getDocs } from "firebase/firestore";
import { ResultModel } from "../models/result.model";

export async function addResult(result: ResultModel) {
  const docRef = doc(ResultModel.collectionReference);
  await setDoc(docRef, result);
}

export async function fetchAllResults(): Promise<ResultModel[]> {
  const q = query(ResultModel.collectionReference);
  const snapshots = await getDocs(q);

  return snapshots.docs.map((doc) => doc.data());
}
